import vars from "../_vars.js";
const { forms } = vars;

if (forms) {
  forms.forEach((form) => {
    const ratingStars = form.querySelectorAll(".rating__star");
    const ratingInput = form.querySelector('input[name="rating"]');
    const textFields = form.querySelectorAll('input, textarea');

    textFields.forEach((field) => {
      field.addEventListener("input", () => {
        field.classList.add("is-writing");
      });
    
      field.addEventListener("blur", () => {
        field.classList.remove("is-writing");
      });
    
      field.addEventListener("focus", () => {
        if (field.value.trim()) {
          field.classList.add("is-writing");
        }
      });
    });

    if (ratingStars.length === 0 || !ratingInput) return;

    ratingStars.forEach((star) => {
      star.addEventListener("click", () => {
        const ratingValue = star.dataset.value;

        if (!ratingValue) return;
        ratingInput.value = ratingValue;

        ratingStars.forEach((s) => {
          if (s.dataset.value <= ratingValue) {
            s.classList.remove("none");
          } else {
            s.classList.add("none");
          }
        });
      });
    });
  });
}
