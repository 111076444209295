import Chart from "../vendor/chart.js";

import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

function createLineChart(data, element) {
  const ctx = element.getContext("2d");

  const gradientFill = ctx.createLinearGradient(0, 0, 0, 400);
  gradientFill.addColorStop(0, "#ffe5bf");
  gradientFill.addColorStop(1, "#fcf3e6");

  const chartData = {
    labels: Array(8).fill("").map((_, i) => i + 1),
    datasets: [
      {
        label: "Температура",
        data: data,
        fill: true,
        backgroundColor: gradientFill,
        borderColor: "rgba(241, 88, 46, 0.5)",
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 40,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        formatter: (value) => (value > 0 ? `+${value}` : value),
        color: "#000000",
        font: {
          weight: 600,
          size: 15,
        },
        align: (context) => {
            const index = context.dataIndex;
            const dataset = context.dataset.data;
        
            if (index === 0) return "right";
            if (index === dataset.length - 1) return "left";
            return "center";

          },
     
          anchor: "center",
      
      },
      
    },
    scales: {
      x: {
        display: false, 
      },
      y: {
        display: false,
        min: Math.min(...data, 0), 
        max: Math.max(...data),
        grid: {
          drawBorder: false,
          drawTicks: false,
          color: "transparent",
        },
      },
    },
  };

  if (element.chartInstance) {
    element.chartInstance.destroy();
  }

  element.chartInstance = new Chart(ctx, {
    type: "line",
    data: chartData,
    options: chartOptions,
  });
}

const chartsData = {
  1: [14, 12, 12, 12, 10, 10, 8, 8],
  2: [10, 11, 13, 15, 16, 14, 12, 10],
  3: [20, 18, 15, 12, 10, 9, 8, 7],
  4: [20, 18, 15, 12, 10, 9, 8, 7],
  5: [20, 18, 18, 12, 10, 9, 8, 7],
  6: [20, 18, 13, 12, 10, 9, 8, 7],
  7: [20, 18, 7, 12, 10, 9, 8, 7],
  8: [20, 18, 5, 12, 10, 9, 8, 7],
};

function updateCharts() {
  document.querySelectorAll(".main-weather__chart canvas").forEach((element) => {
    const chartType = element.getAttribute("data-chart");
    const data = chartsData[chartType];

    if (data) {
      createLineChart(data, element);
    }
  });
}

const resizeObserver = new ResizeObserver(updateCharts);

document.querySelectorAll(".main-weather__chart canvas").forEach((element) => {
  resizeObserver.observe(element);
});

window.addEventListener("load", updateCharts);
window.addEventListener("resize", updateCharts);
