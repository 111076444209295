import Swiper from "../vendor/swiper.js";
import vars from "../_vars.js";

document.addEventListener("DOMContentLoaded", function () {
  const {
    popularSliders,
    objectSliders,
    publicationsSliders,
    interestingSliders,
    infoSlider,
  } = vars;

  popularSliders.forEach(function (slider) {
    const container = slider.querySelector(".swiper-container");
    const nextBtn = slider.querySelector(".swiper-btn.next");
    const prevBtn = slider.querySelector(".swiper-btn.prev");

    const popularSwiper = new Swiper(container, {
      spaceBetween: 24,
      slidesPerView: 4,
      speed: 1300,
      watchOverflow: true,
      observer: true,
      observeParents: true,

      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },

      breakpoints: {
        320: {
          spaceBetween: 16,
          slidesPerView: 1.25,
        },
        440: {
          slidesPerView: 1.5,
        },
        540: {
          spaceBetween: 20,
          slidesPerView: 1.9,
        },
        768: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        },
        1110: {
          spaceBetween: 24,
          slidesPerView: 4,
        },
      },
    });
  });

  objectSliders &&
    objectSliders.forEach(function (parent) {
      const mainSwiper = parent.querySelector(".object-slider__main"),
        subSwiper = parent.querySelector(".sub-slider");
      const nextBtn = parent.querySelector(".swiper-btn.next");
      const prevBtn = parent.querySelector(".swiper-btn.prev");

      const subSlider = new Swiper(subSwiper, {
        slidesPerView: 3,
        spaceBetween: 10,
        speed: 1200,
        loop: true,
        centeredSlides: true,
        watchSlidesProgress: true,
        direction: "vertical",

        breakpoints: {
          320: {
            spaceBetween: 8,
            direction: "horizontal",
          },
          576: {
            spaceBetween: 10,
            direction: "vertical",
          },
          768: {
            direction: "vertical",
          },
        },
      });

      const mainSlider = new Swiper(mainSwiper, {
        slidesPerView: 1,
        spaceBetween: 25,
        speed: 1200,
        loop: true,
        centeredSlides: true,

        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
        },

        thumbs: {
          swiper: subSlider,
        },
      });
    });

  publicationsSliders &&
    publicationsSliders.forEach(function (publicationsSlider) {
      let swiperInstance = null;

      function initSwiper() {
        swiperInstance = new Swiper(
          publicationsSlider.querySelector(".swiper-container"),
          {
            spaceBetween: 16,
            speed: 1200,

            breakpoints: {
              320: {
                slidesPerView: 1.15,
              },
              450: {
                slidesPerView: 1.4,
              },
              600: {
                slidesPerView: 1.9,
              },
            },
          }
        );
      }

      function destroySwiper() {
        if (swiperInstance) {
          swiperInstance.destroy(true, true);
          swiperInstance = null;
        }
      }

      function handleResize() {
        if (
          window.innerWidth < 768 ||
          (publicationsSlider.classList.contains("estate") &&
            window.innerWidth < 1024)
        ) {
          if (!swiperInstance) {
            initSwiper();
          }
        } else {
          destroySwiper();
        }
      }
      handleResize();

      window.addEventListener("resize", handleResize);
    });

  if (infoSlider) {
    let swiperInstance = null;

    function initSwiper() {
      swiperInstance = new Swiper(
        infoSlider.querySelector(".swiper-container"),
        {
          spaceBetween: 16,
          speed: 1200,

          breakpoints: {
            320: {
              slidesPerView: 1.5,
            },
            450: {
              slidesPerView: 2.2,
            },
            600: {
              slidesPerView: 2.9,
            },
            840: {
              slidesPerView: 3.9,
            },
          },
        }
      );
    }

    function destroySwiper() {
      if (swiperInstance) {
        swiperInstance.destroy(true, true);
        swiperInstance = null;
      }
    }

    function handleResize() {
      if (window.innerWidth < 1024) {
        if (!swiperInstance) {
          initSwiper();
        }
      } else {
        destroySwiper();
      }
    }
    handleResize();

    window.addEventListener("resize", handleResize);
  }

  interestingSliders.forEach(function (slider) {
    const container = slider.querySelector(".swiper-container");
    const nextBtn = slider.querySelector(".swiper-btn.next");
    const prevBtn = slider.querySelector(".swiper-btn.prev");

    const interestingSwiper = new Swiper(container, {
      spaceBetween: 24,
      slidesPerView: 1,
      speed: 1300,
      watchOverflow: true,
      observer: true,
      observeParents: true,

      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
    });
  });
});
