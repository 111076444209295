import vars from "../_vars.js";
const { categoriesCards } = vars;

categoriesCards &&
  categoriesCards.forEach(function (card) {
    const items = card.querySelectorAll('.categories-card__item');
    const openButton = card.querySelector('.categories-card__open');
    const maxVisibleItems = 10;

    function updateItemsVisibility(expanded) {
      items.forEach((item, index) => {
        if (index < maxVisibleItems || expanded) {
          item.style.height = '';
        } else {
          item.style.height = '0';
        }
      });
    }

    let expanded = false;
    updateItemsVisibility(expanded);

    openButton &&
      openButton.addEventListener('click', () => {
        expanded = !expanded;
        updateItemsVisibility(expanded);

        openButton.textContent = expanded ? 'Показать меньше' : 'Показать больше';
        openButton.classList.toggle('active', expanded);
      });
  });
