import './components/dinamicHeight.js';
import './components/sliders.js';
import './components/modals.js';
import './components/acc.js';
import './components/mobile-menu.js';
import './components/tabs.js';
import './components/categories.js';
import './components/select.js';
import './components/rating.js';
import './components/fancybox.js';
import './components/chart.js';
