export default {
  windowEl: window,
  documentEl: document,
  htmlEl: document.documentElement,
  bodyEl: document.body,
  activeClass: 'active',
  activeClassMode: 'mode',
  header: document.querySelector('header'),
  footer: document.querySelector('footer'),
  categoriesCards: document.querySelectorAll('.categories-card'),
  popularSliders: document.querySelectorAll('.popular-slider'),
  forms: document.querySelectorAll('.main-form'),
  objectSliders: document.querySelectorAll('.object-slider'),
  publicationsSliders: document.querySelectorAll('.publications-slider'),
  infoSlider: document.querySelector('.info-slider'),
  interestingSliders: document.querySelectorAll('.interesting-slider'),


  burger: document.querySelectorAll('.burger'),
  mobileMenu: document.querySelector('.mobile'),
  overlay: document.querySelector('[data-overlay]'),
  modals: [...document.querySelectorAll('[data-popup]')],
  modalsMode: [...document.querySelectorAll('[data-mode-modal]')],
  modalsButton: [...document.querySelectorAll("[data-btn-modal]")],
}
